import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Auth, authState } from '@angular/fire/auth';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  signinForm: FormGroup;
  redirectLink: any;
  errorMsg: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public cookieService: CookieService,
    public http: HttpClient,
    public auth: Auth
  ) {
    this.signinForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
    authState(this.auth).subscribe((user) => {
      if (user) {
        this.redirectLink = this.route.snapshot.queryParamMap.get('redirectto');
        this.getTokenAndRedirect(user);
      }
    });
  }
  ngOnInit(): void {
  }
  async submit() {
    try {
      let res = await this.authService.login(this.signinForm.value);
      this.getTokenAndRedirect(res.user);
    } catch (err: any) {
      console.log(err);
      this.errorMsg = err.message;
    }
  }

  async getTokenAndRedirect(user: any) {
    let userdata:any=(await this.authService.getUserData(user.uid)).data()
    if(userdata.status){
      this.http
      .get(`${environment.apiUrl}/create_custom_token_uid?uid=${user.uid}`)
      .subscribe((res: any) => {
        if (this.redirectLink) {
          window.location.href = this.redirectLink + '?idToken=' + res.response;
        } else {
          window.location.href =
            environment.defaultRedirectAppUrl + '?idToken=' + res.response;
        }
      });
    }else{
      await this.authService.signout()
      this.errorMsg="Your are blocked by admin, Please contact adminstrator"
    }
   
  }
}

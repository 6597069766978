import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  resetForm: FormGroup;
  errorMsg: any;
  successMsg: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.resetForm = new FormGroup({
      email: new FormControl('', Validators.required),
    });
  }

  async submit() {
    try {
      await this.authService.restPassword(this.resetForm.value).then((res) => {
        this.successMsg = 'Reset Password Link sent to your email';
        this.resetForm.reset()
      });
    } catch (err: any) {
      console.log(err);
      if (err.message.includes('auth/invalid-email')) {
        this.errorMsg = 'Invalid Email';
      } else {
        this.errorMsg = 'Error in sending email';
      }
    }
  }
}

<div class="container-fluid">
  <form [formGroup]="signinForm">
    <div class="row">
      <div class="col-md-4 mx-auto">
        <mat-card>
          <mat-card-content>
            <div class="text-center">
              <p class="text-muted m-0">Sign in to your account</p>
              <p *ngIf="errorMsg" class="text-center">
                <small class="text-red">{{ errorMsg }}</small>
              </p>
            </div>
            <div class="form-group mt-3">
              <mat-form-field class="full-width">
                <input
                  matInput
                  name="email"
                  placeholder="Email"
                  formControlName="email"
                />
              </mat-form-field>
            </div>
            <div class="form-group my-3">
              <mat-form-field class="full-width">
                <input
                  matInput
                  name="password"
                  placeholder="Password"
                  formControlName="password"
                  type="password"
                />
              </mat-form-field>
            </div>
            <div class="form-group">
              <button
                mat-raised-button
                (click)="submit()"
                color="primary"
                class="mat-primary full-width mb-4 w-100"
                [disabled]="signinForm.invalid"
              >
                Sign in
              </button>
            </div>
            <div class="form-group">
              <a
                [routerLink]="['/reset-link']"
                class="mat-primary text-center full-width"
                >Reset Link?</a
              >
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>

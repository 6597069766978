import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import {
  Auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from '@angular/fire/auth';
import {
  Firestore, doc, getDoc
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  firestore: Firestore = inject(Firestore);
  auth: Auth = inject(Auth);
  db: Firestore = inject(Firestore);
  constructor(public router: Router,public cookieService: CookieService ) {}
  async login(loginData: any) {
    try {
      let res = await signInWithEmailAndPassword(
        this.auth,
        loginData.email,
        loginData.password
      );
      if (res.user.uid) {
        localStorage.setItem('uid', res.user.uid); 
      }
      return res;
    } catch (err: any) {
      console.log(err);
      // this.common.showError('Error', 'Incorrect Credentials, try again...');
      throw {
        message: 'Incorrect Credentials, try again...',
      };
    }
  }
  async restPassword(data: any) {
    try {
      console.log(data.email)
       await sendPasswordResetEmail(this.auth, data.email)
      return true;
    } catch (err: any) {
      console.log(err,"err")
      throw {
        message: err.message,
      };
    }
  }

  signout(){
    this.cookieService.deleteAll()
    signOut(this.auth)
  }

  getUserData(uid:any){
    let d = doc(this.db,"Users/"+uid)
    return getDoc(d)
  }
}

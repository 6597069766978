export const config = {
  defaultRedirectAppUrl: 'https://google.com',
  apiUrl: 'https://us-central1-devqwipo.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyDvil7FitRz5FvCvqV_elC9e9877TtImMU',
    authDomain: 'devqwipo.firebaseapp.com',
    databaseURL: 'https://devqwipo.firebaseio.com',
    projectId: 'devqwipo',
    storageBucket: 'devqwipo.appspot.com',
    messagingSenderId: '95302609868',
    appId: '1:95302609868:web:57d524c8593ac77f2ecb69',
    measurementId: 'G-2G1N0P1XC9',
  },
  defaultDomain: 'web.app',
};

export const testConfig = {
  defaultRedirectAppUrl: 'https://google.com',
  apiUrl: 'https://us-central1-test-qwipo.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyDJAIUo7IwRN55S4TSs1caRLBzqKLpJiIE',
    authDomain: 'test-qwipo.firebaseapp.com',
    databaseURL: 'https://test-qwipo.firebaseio.com',
    projectId: 'test-qwipo',
    storageBucket: 'test-qwipo.appspot.com',
    messagingSenderId: '1002423689795',
    appId: '1:1002423689795:web:368c953cccebfe247b2af6',
    measurementId: 'G-V0VVNGXRZ9',
  },
  defaultDomain: 'web.app',
};

export const stagingConfig = {
  defaultRedirectAppUrl: 'https://google.com',
  apiUrl: 'https://us-central1-staging-qwipo-2015.cloudfunctions.net',
  firebaseConfig: {
    appId: '1:1016985034148:web:8a64c128f2aaa815c47531',
    projectId: 'staging-qwipo-2015',
    apiKey: 'AIzaSyDDMgqsy8EwbRHE7mRzO6jvZX0hwNXGJcs',
    authDomain: 'staging-qwipo-2015.firebaseapp.com',
    databaseURL: 'https://staging-qwipo-2015-default-rtdb.firebaseio.com',
    storageBucket: 'staging-qwipo-2015.appspot.com',
    messagingSenderId: '1016985034148',
    measurementId: 'G-RKTLZXSC6K',
  },
  defaultDomain: 'web.app',
};

export const prodConfig = {
  defaultRedirectAppUrl: 'https://google.com',
  apiUrl: 'https://us-central1-qwipo-82b7a.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyDbV-CkDA4kFmMdmjgl_MSWIfqUh9YNSpE',
    authDomain: 'qwipo-82b7a.firebaseapp.com',
    databaseURL: 'https://qwipo-82b7a.firebaseio.com',
    projectId: 'qwipo-82b7a',
    storageBucket: 'qwipo-82b7a.appspot.com',
    messagingSenderId: '719176192291',
    appId: '1:719176192291:web:0bd89bef14d848cc2a3c96',
    measurementId: 'G-BGYZX6JL0R',
  },
  defaultDomain: 'web.app',
};
